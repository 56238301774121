import React, { useState, useEffect, useRef } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import './survey.css';
import { useParams, useNavigate } from "react-router-dom";
import { FunctionFactory } from "survey-core";
import { Typography, CircularProgress } from '@mui/material';
import ErrorComponent from './ErrorComponent';

const DEBUG = process.env.REACT_APP_DEBUG || false;

FunctionFactory.Instance.register("convertString", (params) => String(params));

function SurveyComponent() {
  const [surveyModel, setSurveyModel] = useState(null);
  const [eventVars, setEventVars] = useState(null);

  const [clientStatus, setClientStatus] = useState('loading');

  const currentUrl = window.location.href;



  const loadedScript = useRef(false);
  const { urlid } = useParams();
  const navigate = useNavigate();

  const questionId = urlid?.split('_sd_')[0];
  const requestId = urlid?.split('_sd_').slice(1).join('');

  useEffect(() => {
    if (!loadedScript.current) loadAnalyticsScript();
  }, []);

  const loadAnalyticsScript = () => {
    const env = process.env.REACT_APP_ENV;
    const isProduction = env === 'production';
    const script = document.createElement('script');
    script.src = isProduction
      ? "https://analytics.uzeli.com/gz_analytics.min.js"
      : "https://analyticsstaging.uzeli.com/gz_analytics.min.js";
    script.async = false;

    script.onload = () => DEBUG && console.log('GZAnalytics loaded');
    script.onerror = () => console.log('Failed to load GZAnalytics script.');

    document.body.appendChild(script);
    loadedScript.current = true;
  };

  const fetchEventData = async () => {
    try {
      const response = await fetch('/geteventvars');
      if (!response.ok) throw new Error('Network response was not ok');
      return await response.json();
    } catch (error) {
      setClientStatus('serverError');
      console.error("Error fetching event vars:", error);
    }
  };








  const makeSurveyModel = (questionData) => {
    const { json, theme } = questionData;
    const survey = new Model(json);
    survey.applyTheme(theme);
    return survey;
  };

  const formatServicesData = (services) => {
    const formatList = (list) => ({
      commaList: list.join(", "),
      toLowercaseCommaList: list.join(", ").toLowerCase(),
      htmlBulletList: `<ul>${list.map(item => `<li>${item}</li>`).join('')}</ul>`,
      markdownList: list.map(item => `- ${item}`).join("\n"),
    });

    const categorizedServices = services.reduce((acc, service) => {
      acc[service.category_name] = acc[service.category_name] || { services: [] };
      acc[service.category_name].services.push(service.name);
      return acc;
    }, {});

    Object.keys(categorizedServices).forEach(category => {
      categorizedServices[category].services = formatList(categorizedServices[category].services);
    });

    return categorizedServices;
  };

  // console.log('outside', surveyModel, eventVars)

  const renderContent = () => {
    //  console.log("here:", surveyModel, eventVars);
    if (clientStatus === 'loading') return <ErrorComponent><CircularProgress /><div> Please wait while loading </div></ErrorComponent>;
    if (clientStatus === 'serverError') return <ErrorComponent><Typography variant="h6">Oops! Server Error.</Typography></ErrorComponent>;
    if (clientStatus === 'responded') return <ErrorComponent><Typography variant="h6">You already filled out the survey.</Typography></ErrorComponent>;

    return surveyModel && eventVars ? <Survey model={surveyModel} /> : <ErrorComponent><CircularProgress /></ErrorComponent>;
  };

  useEffect(() => {


    const logSurveyEvent = (name, campaign, survey, additionalData = {}) => {
      const analytics = window.GZAnalytics;
      if (!analytics) return;

      analytics.customLog({
        name,
        eventtype: name,
        payload: {
          surveyCampaignName: campaign?.name,
          surveyCampaignDescription: campaign?.description,
          questionId,
          questionTitle: survey.title,
          questionDescription: survey.description,
          totalPage: survey.pageCount,
          ...additionalData
        }
      });
    };

    const navigateOnCompletion = (survey, links, designSettings, redirectUrl) => {
      const brandName = survey.getVariable('BrandName');
      const businessName = survey.getVariable('businessName');
      if (!redirectUrl && links) {
        navigate('/thankyou', {
          state: { links, LocationName: businessName, brandName, designSettings }
        });
      } else {
        navigate(redirectUrl);
      }
    };


    const updateSurveyVariables = (survey, eventVars) => {
      if (!survey || !eventVars) return;
      const { services = [], products = [], ...otherVars } = eventVars;

      const serviceObj = formatServicesData(services);
      const productObj = formatServicesData(products);

      survey.setVariable("serviceObj", serviceObj);
      survey.setVariable("services", services.map(s => s.name));
      survey.setVariable("productObj", productObj);
      for (const [key, value] of Object.entries(otherVars)) {
        survey.setVariable(key, value);
      }
    };



    const fetchQuestionData = async () => {
      try {
        const response = await fetch(`/questions/${questionId}`);
        if (!response.ok) throw new Error('Failed to fetch question data');
        return await response.json();
      } catch (error) {
        console.error("Error fetching question data: ", error);
      }
    };


    const addSurveyAnalytics = (survey, eventData) => {
      const business = eventData?.business;
      const campaign = eventData?.campaign;
      const designSettings = eventData?.designSettings;
      const links = eventData?.links;
      const redirectUrl = eventData?.redirectUrl;

      const analytics = window.GZAnalytics;
      if (!analytics) return console.log('GZAnalytics not available');

      analytics.init('survey-local');
      analytics.setBusinessData({
        businessLocationId: business.id,
        networkId: business.networkId,
        businessLocationName: business.name,
      });

      const handleSurveyComplete = (sender) => {
        const surveyData = {
          formData: sender.data,
          requestId,
          questionId,
          responseFrom: currentUrl.endsWith('/t') ? 'text' : 'email'
        };

        fetch('/submit', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(surveyData),
        })
          .then(response => {
            if (!response.ok) throw new Error('Network response was not ok');
            return response.json();
          })
          .then(() => navigateOnCompletion(sender, links, designSettings, redirectUrl))
          .catch(error => console.error("Error submitting survey:", error));
      };

      const handlePageChange = (sender, options) => {
        const { newCurrentPage: newPage, oldCurrentPage: oldPage } = options;
        logSurveyEvent('Next Button Clicked', sender, campaign, { newPage, oldPage, direction: options.isGoingForward ? 'forward' : 'backward' });
      };

      survey.onComplete.add(handleSurveyComplete);
      survey.onCurrentPageChanged.add(handlePageChange);
      survey.onStarted.add(() => logSurveyEvent('Survey Started', campaign, survey));
    };

    const initializeSurvey = async (serverResponseData) => {
      const questionData = await fetchQuestionData();
      const survey = makeSurveyModel(JSON.parse(questionData?.data));
      setSurveyModel(survey);
      updateSurveyVariables(survey, serverResponseData?.eventVars);
      addSurveyAnalytics(survey, serverResponseData);
    };

    const fetchData = async () => {
      const response = await fetchEventData();
      const serverResponseData = response.serverResponse?.data;
      setClientStatus(serverResponseData?.status || 'loading');
      if (serverResponseData.status === 'responded') return;
      setEventVars(serverResponseData?.eventVars);

      await initializeSurvey(serverResponseData)
    };

    if (questionId) fetchData();
  }, [urlid, questionId, currentUrl, requestId, navigate]);

  return renderContent();
}

export default SurveyComponent;



